

<template>
   <div class="col-md-7 col-sm-7 mt-10 boxmain">
          <div class="container boxrouting">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n">
                  {{$t('order.title')}}
                </h2>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12col-md-12 block-1">
                <div class="search_form search_box_HD">

                  <form action="" accept-charset="UTF-8" @submit="onSearch" id="order_search" class="container-search">
                    <div class="search_box">
                      <div class="form-group">
                        <input class="txt_keyword" v-model="keyword" :placeholder="$t('order.placeholder')" name="name" type="text" autocomplete="off">
                      </div>
                    </div>
                    <div class="search_btn"> 
                      <button type="submit" class="btn btn-danger btn-block"> 
                      {{$t('order.button')}}
                    </button> </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div class="container boxrouting" v-if="is_loading">
             <SkeletonTheme color="#e6f3fd" highlight="#eef6fd">
                <div class="row">
                  <div class="col-sm-12 col-xs-12 col-ms-12">
                    <div class="white-box">
                      <div class="box_title">
                        <h4 class="box-title m-b-0">
                          {{$t('order.result')}}
                        </h4>
                        <p class="text-muted m-b-20">
                           {{$t('order.info')}}
                        </p>
                      </div>

                      <div class="table-responsive">
                        <table class="table table-bordered" id="tableInvoice">
                          <thead>
                            <tr>
                              <th>
                                {{$t('order.t1')}}
                              </th>
                              <th>
                                 {{$t('order.t2')}}
                              </th>
                              <th>
                                 {{$t('order.t3')}}
                              </th>
                              <th>
                                 {{$t('order.t4')}}
                              </th>
                              <th>
                                 {{$t('order.t5')}}
                              </th>
                              <th>
                                 {{$t('order.t6')}}
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <Skeleton/>
                              </td>
                              <td>
                                <Skeleton/>
                              </td>
                              <td>
                                <Skeleton/>
                              </td>
                              <td>
                                <Skeleton/>
                              </td>
                              <td>
                                <Skeleton/>
                              </td>
                              <td>
                                <Skeleton/>
                                 <Skeleton/>
                              </td>
                            </tr>
                          </tbody>

                        </table>
                      </div>
                    </div>
                  </div>

                </div>
          </SkeletonTheme>
          </div>
          <div class="container boxrouting">
            <div class="row">
              <div class="col-sm-12 col-xs-12 col-ms-12">
                <h2 class="titleh2n">
                  {{$t('order.other.title')}}
                </h2>
              </div>
            </div>
          </div>
          <div class="container">
            <div class="row">
              <div class="col-xl-12">
                <div class="row no-gutters-1">
                  <div class="col-md-6 col-xl-6">
                    <div class="about-feature-box-type2 z-index-1">
                      <div class="about-feature-box-icon">
                        <img class="lazy" src="@/assets/default/images/ic_save_coin.svg">
                      </div>
                      <div class="about-feature-box-content">
                        <div class="title">
                          <a href="#" title="#">
                            <h3>
                              {{$t('order.other.tk1')}}
                            </h3>
                          </a>
                        </div>
                        <div class="para">
                          <p>

                            {{$t('order.other.tk2')}}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-xl-6">
                    <div class="about-feature-box-type2 z-index-1">
                      <div class="about-feature-box-icon">
                        <img class="lazy" src="@/assets/default/images/ic_safe.svg">
                      </div>
                      <div class="about-feature-box-content">
                        <div class="title">
                          <a href="#" title="#">
                            <h3>
                              {{$t('order.other.at1')}}
                            </h3>
                          </a>
                        </div>
                        <div class="para">
                          <p>
                            {{$t('order.other.at2')}}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-xl-6">
                    <div class="about-feature-box-type2 z-index-1">
                      <div class="about-feature-box-icon">
                        <img class="lazy" src="@/assets/default/images/ic_fast.svg">
                      </div>
                      <div class="about-feature-box-content">
                        <div class="title">
                          <a href="#" title="#">
                            <h3>{{$t('order.other.nc1')}}</h3>
                          </a>
                        </div>
                        <div class="para">
                          <p>
                            {{$t('order.other.nc2')}}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 col-xl-6">
                    <div class="about-feature-box-type2 z-index-1">
                      <div class="about-feature-box-icon">
                        <img class="lazy" src="@/assets/default/images/ic_flexible.svg">
                      </div>
                      <div class="about-feature-box-content">
                        <div class="title">
                          <a href="#" title="#">
                            <h3>{{$t('order.other.lh1')}}</h3>
                          </a>
                        </div>
                        <div class="para">
                          <p>
                            {{$t('order.other.lh2')}}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

           <TopNews :Header="2"/>
        </div>
</template>

<script>
import { Skeleton, SkeletonTheme } from "vue-loading-skeleton";

  
  import TopNews from "@/components/default/TopNews.vue";


export default {
  
 
  data() {
    return {
     
      myurl:window.my_url,
      
      my_data:null,
      keyword:"",
      is_loading:false

    };
  },

  components: {
    Skeleton,
    SkeletonTheme,
    TopNews
    
  },
  created(){
        

          

          
          
  },
  methods:{
    onSearch(event){
      event.preventDefault();
      this.is_loading=true;
      return false;
    }
  }

}
</script>